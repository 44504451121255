var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-table',{attrs:{"fields":[
    { key: 'type', label: '' },
    { key: 'value', label: '' } ],"items":[
    { type: 'Vencidos', value: +_vm.data.scr.scr_analyse.expired + _vm.sum(_vm.groupScr, 'expired') },
    { type: 'A Vencer', value: +_vm.data.scr.scr_analyse.due + _vm.sum(_vm.groupScr, 'due') },
    { type: 'Curto Prazo', value: +_vm.data.scr.scr_analyse.short_term_due + _vm.sum(_vm.groupScr, 'short_term_due') },
    { type: 'Médio Prazo', value: +_vm.data.scr.scr_analyse.mid_term_due + _vm.sum(_vm.groupScr, 'mid_term_due') },
    { type: 'Longo Prazo', value: +_vm.data.scr.scr_analyse.long_term_due + _vm.sum(_vm.groupScr, 'long_term_due') },
    { type: 'Prejuizo', value: +_vm.data.scr.scr_analyse.prejudice + _vm.sum(_vm.groupScr, 'prejudice') } ]},scopedSlots:_vm._u([{key:"cell(value)",fn:function(currentData){return [((typeof currentData.item.value) === 'boolean')?_c('span',[_vm._v(" "+_vm._s(currentData.item.value ? 'Sim' : 'Não')+" ")]):_c('span',[_vm._v(" "+_vm._s(_vm._f("value")(currentData.item.value))+" ")])]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }