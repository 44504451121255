<template>
  <b-progress
    :variant="variant"
    :value="value"
    :animated="false"
  />
</template>

<script>
import { BProgress } from 'bootstrap-vue';

export default {
    components: {
        BProgress,
    },
    props: {
        value: {
            type: Number,
            required: true,
        },
    },
    computed: {
        variant() {
            if (this.value > 80 && this.value <= 100) {
                return 'success';
            }

            if (this.value <= 50) {
                return 'danger';
            }

            return 'warning';
        },
    },
};

</script>
