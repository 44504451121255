<template>
  <b-table
    :fields="[
      { key: 'type', label: '' },
      { key: 'value', label: '' },
    ]"
    :items="[
      { type: 'Vencidos', value: +data.scr.scr_analyse.expired + sum(groupScr, 'expired') },
      { type: 'A Vencer', value: +data.scr.scr_analyse.due + sum(groupScr, 'due') },
      { type: 'Curto Prazo', value: +data.scr.scr_analyse.short_term_due + sum(groupScr, 'short_term_due') },
      { type: 'Médio Prazo', value: +data.scr.scr_analyse.mid_term_due + sum(groupScr, 'mid_term_due') },
      { type: 'Longo Prazo', value: +data.scr.scr_analyse.long_term_due + sum(groupScr, 'long_term_due') },
      { type: 'Prejuizo', value: +data.scr.scr_analyse.prejudice + sum(groupScr, 'prejudice') },
    ]"
  >
    <template #cell(value)="currentData">
      <span v-if="(typeof currentData.item.value) === 'boolean'">
        {{ currentData.item.value ? 'Sim' : 'Não' }}
      </span>

      <span v-else>
        {{ currentData.item.value | value }}
      </span>
    </template>
  </b-table>
</template>

<script>
import { BTable } from 'bootstrap-vue';

export default {

    components: {
        BTable,
    },
    props: {
        data: {
            type: Object,
            required: true,
        },
    },

    computed: {
        groupScr() {
            return this.data.group.map(item => item.scr);
        },
    },

    methods: {
        sum(
            arr,
            key,
        ) {
            return arr.reduce((acc, item) => acc + +item[key], 0);
        },
    },
};
</script>
